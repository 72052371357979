import styles from './styles.module.css';

const Footer = () => {
  return (
    <div className={styles.footerContainer} id='contact'>
      <div className={styles.contentContainer}>
        <div>
          <h1 className={styles.title}>Say Hi!</h1>
          <div className={styles.contactInfo}>
            <p>Email me at:</p>
            <a href='mailto: raul.rogojan77@gmail.com'>
              raul.rogojan77@gmail.com
            </a>
          </div>
          <div className={styles.contactInfo}>
            <p>Direct message me on LinkedIn:</p>
            <a
              href='https://www.linkedin.com/in/raul-rogojan-b3442ba9/'
              target='_black'
            >
              Raul Rogojan
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <p>All right reserved</p>
        <p>
          Designed and Developed by{' '}
          <a
            href='https://www.linkedin.com/in/raul-rogojan-b3442ba9/'
            target='_black'
          >
            Raul Rogojan
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
