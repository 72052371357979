import { useRef, useEffect, useState, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { CustomEase, ScrollTrigger } from 'gsap/all';
import SplitType from 'split-type';

import useIsMobile from 'hooks/useIsMobile';
import { ReactComponent as Cubs } from 'assets/svgs/cubsForAnim.svg';

import styles from './styles.module.css';

const Hero = () => {
  gsap.registerPlugin(CustomEase);

  const [cubsArray, setCubsArray] = useState([]);
  const [lightTopContainer, setLightTopContainer] = useState();
  const [lightBottomContainer, setLightBottomContainer] = useState();
  const [firstCubBottomFace, setFirstCubBottomFace] = useState();
  const [firstCubRightFace, setFirstCubRightFace] = useState();
  const [secondCubTopFace, setSecondCubTopFace] = useState();
  const [secondCubLeftFace, setSecondCubLeftFace] = useState();
  const [cubShadow, setCubShadow] = useState();

  const tl = useRef();
  const scrollTl = useRef();
  const secondaryTextRef = useRef();
  const heroContainerRef = useRef();
  const line1Ref = useRef();
  const line2Ref = useRef();
  const line3Ref = useRef();
  const cubsRef = useRef();
  const cubsContainerRef = useRef();
  const textContainerRef = useRef();

  const wordsStagger = 0.08;
  const wordsAnimDuration = 1;
  const cubsLightOpacity = 0.5;
  const cubsAnimDuration = 2;
  const animationEase = 'power2.out';
  const animationEaseAndOut = 'slow(0.3, 0.3, false)';
  const customEaseOut = CustomEase.create(
    'custom',
    'M0,0 C0,0.092 0.396,1.018 1,1 '
  );
  const customEaseOutSlow = CustomEase.create(
    'custom',
    'M0,0 C0,0.74 0.396,1.018 1,1 '
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    const cubs = cubsRef.current.querySelectorAll('#cub');
    setCubsArray(Array.from(cubs));

    new SplitType('#greetingFormula', {
      types: 'words',
      wordClass: 'greetingChar',
    });

    new SplitType('#mainText', {
      types: 'words',
      wordClass: 'mainTextChar',
    });

    new SplitType('#mainTextExtension', {
      types: 'words',
      wordClass: 'mainTextExtensionChar',
    });
  }, []);

  useEffect(() => {
    setLightTopContainer(cubsRef.current.querySelector('#lightTopContainer'));
    setLightBottomContainer(
      cubsRef.current.querySelector('#lightBottomContainer')
    );
    setFirstCubBottomFace(
      cubsRef.current.querySelector('#cub1BottomFaceLight')
    );
    setFirstCubRightFace(cubsRef.current.querySelector('#cub1RightFaceLight'));
    setSecondCubTopFace(cubsRef.current.querySelector('#cub2TopFaceLight'));
    setSecondCubLeftFace(cubsRef.current.querySelector('#cub2LeftFaceLight'));
    setCubShadow(cubsRef.current.querySelector('#shadow'));
  }, [
    lightTopContainer,
    lightBottomContainer,
    firstCubBottomFace,
    firstCubRightFace,
    secondCubTopFace,
    secondCubLeftFace,
    setCubShadow,
  ]);

  useEffect(() => {
    const context = gsap.context(() => {
      tl.current = gsap.timeline();

      gsap.set('.greetingChar', {
        y: 110,
        autoAlpha: 0,
      });
      gsap.set('.mainTextChar', {
        y: 110,
        autoAlpha: 0,
      });
      gsap.set('.mainTextExtensionChar', {
        y: 110,
        autoAlpha: 0,
      });
      gsap.set(secondaryTextRef.current, {
        y: 110,
        autoAlpha: 0,
      });
      gsap.set(cubsArray, {
        autoAlpha: 0,
        yPercent: 50,
      });
      gsap.set(lightTopContainer, {
        autoAlpha: 0,
        y: -50,
      });
      gsap.set(lightBottomContainer, {
        autoAlpha: 0,
        y: 50,
      });
      gsap.set(firstCubBottomFace, {
        autoAlpha: 0,
      });
      gsap.set(firstCubRightFace, {
        autoAlpha: 0,
      });
      gsap.set(secondCubTopFace, {
        autoAlpha: 0,
      });
      gsap.set(secondCubLeftFace, {
        autoAlpha: 0,
      });
      gsap.set(cubShadow, {
        scale: 0,
        transformOrigin: 'center center',
      });

      tl.current.to(heroContainerRef.current, {
        autoAlpha: 1,
        duration: 0.1,
      });

      tl.current
        .to(textContainerRef.current, {
          display: 'initial',
          duration: 0.1,
        })
        .to(
          cubsRef.current,
          {
            display: 'initial',
            duration: 0.1,
          },
          '<'
        )
        .to(cubsArray, {
          autoAlpha: 1,
          yPercent: 0,
          stagger: 0.05,
          duration: 1.5,
          ease: customEaseOutSlow,
        })
        .to(
          cubShadow,
          {
            scale: 1,
            yPercent: 0,
            stagger: 0.05,
            duration: 1.5,
            ease: customEaseOutSlow,
          },
          '<'
        )
        .to(cubsContainerRef.current, {
          x: isMobile ? '10%' : '70%',
          duration: 1,
          ease: customEaseOut,
        })
        .to(
          lightTopContainer,
          {
            y: 0,
            autoAlpha: 1,
            duration: 1,
            ease: customEaseOut,
          },
          '<'
        )
        .to(
          firstCubRightFace,
          {
            autoAlpha: 1,
          },
          '<'
        )
        .to(
          secondCubTopFace,
          {
            autoAlpha: 1,
          },
          '<'
        )
        .to(
          lightBottomContainer,
          {
            y: 0,
            autoAlpha: 1,
            duration: 1,
            ease: customEaseOut,
          },
          '<'
        )
        .to(
          firstCubBottomFace,
          {
            autoAlpha: 1,
          },
          '<'
        )
        .to(
          secondCubLeftFace,
          {
            autoAlpha: 1,
          },
          '<'
        )
        .to(
          '.greetingChar',
          {
            y: 0,
            autoAlpha: 1,
            stagger: wordsStagger,
            duration: wordsAnimDuration,
            ease: animationEase,
          },
          '<'
        )
        .to(
          '.mainTextChar',
          {
            y: 0,
            autoAlpha: 1,
            stagger: wordsStagger,
            duration: wordsAnimDuration,
            ease: animationEase,
          },
          '<'
        )
        .to(
          '.mainTextExtensionChar',
          {
            y: 0,
            autoAlpha: 1,
            stagger: wordsStagger,
            duration: wordsAnimDuration,
            ease: animationEase,
          },
          '<'
        )
        .to(
          secondaryTextRef.current,
          {
            y: 0,
            autoAlpha: 1,
            duration: wordsAnimDuration,
            ease: animationEase,
          },
          '<'
        )
        .to(lightBottomContainer, {
          y: -5,
          duration: cubsAnimDuration,
          repeat: -1,
          yoyo: true,
          ease: animationEaseAndOut,
        })
        .to(secondCubLeftFace, {
          autoAlpha: cubsLightOpacity,
          duration: cubsAnimDuration,
          repeat: -1,
          yoyo: true,
          ease: animationEaseAndOut,
        })
        .to(
          firstCubBottomFace,
          {
            autoAlpha: cubsLightOpacity,
            duration: cubsAnimDuration,
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          `-=${cubsAnimDuration}`
        )
        .to(
          lightTopContainer,
          {
            y: -10,
            duration: cubsAnimDuration,
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          `-=${cubsAnimDuration * 2}`
        )
        .to(
          secondCubTopFace,
          {
            autoAlpha: cubsLightOpacity,
            duration: cubsAnimDuration,
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          `-=${cubsAnimDuration}`
        )
        .to(
          firstCubRightFace,
          {
            autoAlpha: cubsLightOpacity,
            duration: cubsAnimDuration,
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          `-=${cubsAnimDuration}`
        )
        .to(
          cubsArray[0],
          {
            yPercent: -3,
            duration: cubsAnimDuration,
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          '<'
        )
        .to(
          cubsArray[1],
          {
            yPercent: 2,
            duration: cubsAnimDuration,
            transformOrigin: 'center center',
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          '<'
        )
        .to(
          cubsArray[2],
          {
            yPercent: -10,
            duration: cubsAnimDuration,
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          '<'
        )
        .to(
          cubShadow,
          {
            scale: 0.8,
            duration: cubsAnimDuration,
            repeat: -1,
            yoyo: true,
            ease: animationEaseAndOut,
          },
          '<'
        );
    });

    return () => context.revert();
  }, [cubsArray, isMobile]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const context = gsap.context(() => {
      scrollTl.current = gsap.timeline({
        scrollTrigger: {
          trigger: heroContainerRef.current,
          start: 'top top',
          scrub: 1,
        },
      });

      scrollTl.current
        .to(secondaryTextRef.current, {
          y: -100,
        })
        .to(
          line1Ref.current,
          {
            x: 50,
          },
          '<'
        )
        .to(
          line2Ref.current,
          {
            x: -100,
          },
          '<'
        )
        .to(
          line3Ref.current,
          {
            x: 150,
          },
          '<'
        );
    });

    return () => context.revert();
  }, [isMobile]);

  return (
    <div className={styles.heroContainer} ref={heroContainerRef} id='home'>
      <div ref={cubsContainerRef} className={styles.illustrationContainer}>
        <Cubs className={styles.cubs} ref={cubsRef} />
      </div>
      <div className={styles.contentContainer} ref={textContainerRef}>
        <h1 id='greetingFormula' ref={line1Ref}>
          HI, I am Raul
        </h1>
        <h1 id='mainText' ref={line2Ref}>
          A passionate react
        </h1>
        <h1 id='mainTextExtension' ref={line3Ref}>
          developer
        </h1>
        <div className={styles.secondaryText}>
          <p ref={secondaryTextRef}>
            A developer with a background in graphic design...
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
