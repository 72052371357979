import { useEffect, useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger, ScrollToPlugin } from 'gsap/all';
import { useLocation, useNavigate } from 'react-router-dom';

import Hero from './sections/Hero';
import About from './sections/About';
import Experience from './sections/Experience';
import Projects from './sections/Projects';
import Footer from 'components/Footer';

const Homepage = () => {
  gsap.registerPlugin(ScrollToPlugin);

  const location = useLocation();

  useEffect(() => {
    const scrollPosition = location.hash;

    gsap.to(window, { duration: 0, scrollTo: scrollPosition ?? 0 });

    if (window.location.hash) {
      window.history.replaceState('', document.title, window.location.pathname);
    }
  }, [location.hash, location]);

  return (
    <div>
      <Hero />
      <About />
      <Experience />
      <Projects />
    </div>
  );
};

export default Homepage;
