import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const Tabs = ({ config }) => {
  const [activeTab, setActiveTab] = useState(0);

  const isMobile = useIsMobile(501);
  const isTablet = useIsMobile(1200);

  const tl = useRef();
  const tabsContainerRef = useRef();
  const tabsBG = useRef();
  const tabsContentRef = useRef();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useLayoutEffect(() => {
    ScrollTrigger.refresh();
  }, [activeTab, isMobile]);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const context = gsap.context(() => {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: tabsContainerRef.current,
          start: 'top bottom-=50',
          end: 'bottom',
          toggleActions: 'play none none none',
        },
      });

      tl.current
        .from(tabsBG.current, {
          width: 45,
          height: 45,
          duration: 0.5,
          opacity: 0,
        })
        .from('#tabsID', {
          opacity: 0,
        });
    });

    return () => context.revert();
  }, [isMobile, isTablet]);

  return (
    <div ref={tabsContainerRef} className={styles.tabsContainer}>
      <div className={styles.tabsNav}>
        <div ref={tabsBG} className={styles.tabsBG}>
          {config.map((item, index) => (
            <div
              key={index}
              id='tabsID'
              className={`${styles.tab} ${
                activeTab === index && styles.tabActive
              }`}
              onClick={() => handleTabClick(index)}
            >
              {item.tab}
            </div>
          ))}
        </div>
      </div>
      <div ref={tabsContentRef}>{config[activeTab]?.content}</div>
    </div>
  );
};

export default Tabs;
