import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import SplitType from 'split-type';
import { BsFileText } from 'react-icons/bs';

import useWindowSize from 'hooks/useWindowSize';
import useIsMobile from 'hooks/useIsMobile';

import CV from 'assets/pdfs/RogojanRaul-CV.pdf';

import styles from './styles.module.css';

const About = () => {
  const windowSize = useWindowSize();
  const isMobile = useIsMobile();

  const tl = useRef();
  const cvButtonRef = useRef();

  useEffect(() => {
    new SplitType('#text1', {
      types: 'lines',
      lineClass: 'aboutTextLines',
    });
    new SplitType('#text2', {
      types: 'lines',
      lineClass: 'aboutTextLines',
    });
    new SplitType('#text3', {
      types: 'lines',
      lineClass: 'aboutTextLines',
    });
    new SplitType('#text4', {
      types: 'lines',
      lineClass: 'aboutTextLines',
    });
  }, [windowSize]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const context = gsap.context(() => {
      const lines = gsap.utils.toArray('.aboutTextLines');

      gsap.set(lines, {
        yPercent: 100,
        opacity: 0,
      });
      gsap.set(cvButtonRef.current, {
        yPercent: 100,
        opacity: 0,
      });

      lines.forEach((line) => {
        tl.current = gsap.timeline({
          scrollTrigger: {
            trigger: line,
            start: 'clamp(top bottom-=150)',
            end: 'clamp(bottom)',
            toggleActions: 'play none none none',
          },
        });

        tl.current.to(line, {
          yPercent: 0,
          opacity: 1,
          duration: 0.5,
        });
      });
      tl.current.to(cvButtonRef.current, {
        yPercent: 0,
        opacity: 1,
        duration: 0.5,
      });
    });

    return () => context.revert();
  }, [windowSize]);

  return (
    <div className={styles.aboutContainer} id='about'>
      <div className={styles.textContainer}>
        <div className={styles.text}>
          <p id='text1'>
            I specialize in using React to bring visually <mark>stunning</mark>{' '}
            designs to life <mark>through</mark> animations.
          </p>
        </div>
        <div className={styles.text}>
          <p id='text2'>
            I am proficient in <mark>Photoshop</mark>, <mark>Illustrator</mark>{' '}
            and other design tools, with a strong understanding of{' '}
            <mark>front-end development</mark>.
          </p>
          <p id='text3'>
            I have a passion for creating visual appealing and{' '}
            <mark>user-friendly</mark> web applications and have a strong eye
            for <mark>details</mark>.
          </p>
        </div>
        <div className={styles.text}>
          <p id='text4'>
            I am continuously learning and expanding my skill set to stay
            competitive in the <mark>ever-evolving tech industry</mark>.
          </p>
        </div>
      </div>
      <a ref={cvButtonRef} href={CV} target='_blank'>
        <button className={styles.cvButton}>
          <BsFileText className={styles.cvButtonIcon} />
          Download my CV
        </button>
      </a>
    </div>
  );
};

export default About;
