import { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import useIsMobile from 'hooks/useIsMobile';

import styles from './styles.module.css';

const AnimatedGrid = ({ data }) => {
  const rowRef = useRef();
  const linesTl = useRef();
  const textTl = useRef();

  const isMobile = useIsMobile();

  const delayToHideRefresh = 0.3;

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const lines = gsap.utils.toArray('#lines');
    const text = gsap.utils.toArray('#cellsText');

    const context = gsap.context(() => {
      gsap.set(lines, {
        width: 0,
      });
      gsap.set(text, {
        y: 50,
        opacity: 0,
      });

      lines.forEach((line) => {
        linesTl.current = gsap.timeline({
          scrollTrigger: {
            trigger: line,
            targe: '/App',
            start: 'top bottom',
            end: 'bottom',
            toggleActions: 'play none none none',
          },
        });

        linesTl.current.to(line, {
          width: '100%',
          duration: isMobile ? 0.5 : 1,
          delay: delayToHideRefresh,
        });
      });

      text.forEach((text) => {
        textTl.current = gsap.timeline({
          scrollTrigger: {
            trigger: text,
            start: 'top-=50 bottom-=50',
            end: 'bottom-=50',
            toggleActions: 'play none none none',
            duration: isMobile ? 0.5 : 1,
          },
        });

        textTl.current.to(text, {
          y: 0,
          opacity: 1,
          duration: 1,
          delay: delayToHideRefresh,
        });
      });
    });

    return () => context.revert();
  }, []);

  const keys = Object.keys(data[0]);
  return (
    <>
      <div id='lines' className={`${styles.line}`} />
      {data.map((obj, index) => (
        <div ref={rowRef} className={styles.row} key={index}>
          {keys.map((key) => (
            <div className={styles.cell} key={key}>
              <p id='cellsText'>{obj[key]}</p>
            </div>
          ))}
          <div id='lines' className={`${styles.line} ${styles.bottomLine}`} />
        </div>
      ))}
    </>
  );
};

export default AnimatedGrid;
