import { useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

import { projectsData } from 'data';

import styles from './styles.module.css';

const ProjectPage = () => {
  gsap.registerPlugin(ScrollToPlugin);

  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const scrollPosition = location.hash;
    gsap.to(window, { duration: 0, scrollTo: scrollPosition ?? 0 });
  }, [location.hash, location]);

  return (
    <div className={styles.projectPageContainer}>
      {projectsData
        .filter((project) => project.id === id)
        .map((project, index) => (
          <div key={index}>
            <div className={styles.hero}>
              <div className={styles.title}>
                <h1>{project.title}</h1>
              </div>
              <div>
                <div className={styles.image}>
                  <img src={project.image} alt='project image' />
                </div>
              </div>
            </div>
            <div className={styles.description}>
              {project.description.map((item, index) => (
                <p key={index}>{item.paragraph}</p>
              ))}
            </div>
            <div className={styles.buttonsContainer}>
              {project.buttons.map((button, index) => (
                <div key={index}>
                  <a
                    target='_blank'
                    href={button.buttonUrl}
                    className={`${styles.btn} ${styles.btnWhite} ${styles.btnAnimate}`}
                  >
                    {button.buttonText}
                  </a>
                </div>
              ))}
            </div>
            <div className={styles.gallery}>
              {project.images.map((image, index) => (
                <div key={index} className={styles.galleryImgContainer}>
                  <img src={image} alt='project image' />
                </div>
              ))}
            </div>
            <div className={styles.prevNextNav}>
              {project.id === id && id != 1 && (
                <Link
                  to={`/projects/${parseInt(project.id) - 1}`}
                  className={`${styles.prevNextBtn} ${styles.prevBtn}`}
                >
                  <AiOutlineArrowLeft />
                </Link>
              )}
              {id != projectsData.length && (
                <Link
                  to={`/projects/${parseInt(project.id) + 1}`}
                  className={`${styles.prevNextBtn} ${styles.nextBtn}`}
                >
                  <AiOutlineArrowRight />
                </Link>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProjectPage;
