import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
// import useLocoScroll from 'hooks/useLocoScroll';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

import Homepage from './pages/Homepage';
import Navigation from 'components/Navigation';
import ProjectPage from 'pages/ProjectPage';
import Footer from 'components/Footer';

import './App.css';

const App = () => {
  // const scroll = useLocoScroll(true);
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   if (scroll) {
  //     scroll.scrollTo('top', {
  //       offset: 0,
  //       duration: 600,
  //       easing: [0.25, 0.0, 0.35, 1.0],
  //       disableLerp: true,
  //     });
  //   }
  // }, [pathname]);

  gsap.registerPlugin(ScrollToPlugin);

  const { pathname } = useLocation();

  useEffect(() => {
    gsap.to(window, { duration: 0, ease: 'power4.out', scrollTo: 0 });
  }, [pathname]);

  gsap.config({
    nullTargetWarn: false,
  });

  return (
    <div data-scroll-container className='App'>
      <Navigation />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/projects/:id' element={<ProjectPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
