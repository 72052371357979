import { useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import useIsMobile from 'hooks/useIsMobile';
import { ScrollTrigger } from 'gsap/all';

import styles from './styles.module.css';

const ProjectsSectionMobile = ({ projectsData }) => {
  const tl = useRef();

  const isMobile = useIsMobile(1200);

  const renderIndex = useMemo(
    () => (index) => {
      return index < 10 ? `0${index + 1}` : index + 1;
    },
    [projectsData]
  );

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const projects = gsap.utils.toArray('#projectsContainer');

    const context = gsap.context(() => {
      projects.forEach((project, index) => {
        const projectsImage = project.querySelector('#projectsImage');
        const projectsIndex = project.querySelector('#projectsIndex');

        gsap.set(projectsImage, {
          x: index % 2 === 0 ? 100 : -100,
          opacity: 0,
        });
        gsap.set(projectsIndex, {
          x: index % 2 === 0 ? 100 : -100,
          opacity: 0,
        });

        tl.current = gsap.timeline({
          scrollTrigger: {
            trigger: project,
            start: 'top bottom-=100',
            end: 'bottom',
            toggleActions: 'restart none none reverse',
            preventOverlaps: true,
          },
        });

        tl.current
          .to(projectsIndex, {
            x: 0,
            opacity: 1,
          })
          .to(
            projectsImage,
            {
              x: 0,
              opacity: 1,
              delay: 0.1,
            },
            '<'
          );
      });
    });

    return () => context.revert();
  }, [isMobile]);

  return (
    <div className={styles.projectsContainer}>
      <h1 className={styles.title}>Projects</h1>
      {projectsData.map((project, index) => (
        <div
          key={index}
          className={styles.projectContainer}
          id='projectsContainer'
        >
          <Link to={`/projects/${project.id}`}>
            <div
              id='projectsImage'
              className={`${styles.projectCard} ${
                index % 2 === 0 ? styles.rotateLeft : styles.rotateRight
              }`}
            >
              <img src={project.image} alt={`project ${project.title}`} />
            </div>
          </Link>
          <div
            id='projectsIndex'
            className={`${styles.projectNumber} ${
              index % 2 === 0 ? styles.posLeft : styles.posRight
            }`}
          >
            <div className={styles.strokeIndex}>{renderIndex(index)}</div>
            <div className={styles.fillIndex}>{renderIndex(index)}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectsSectionMobile;
