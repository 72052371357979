import TabOne from './TabOne';
import TabTwo from './TabTwo';

export const experienceTabs = [
  {
    tab: 'Work',
    content: <TabOne />,
  },
  {
    tab: 'Education',
    content: <TabTwo />,
  },
];
