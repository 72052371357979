import React from 'react';

import AnimatedRow from 'components/AnimatedGrid';

import styles from './styles.module.css';

import { workExperience } from 'data';

const TabOne = () => {
  return (
    <div>
      <h1 className={styles.tabOneTitle}>Work Experience</h1>
      <AnimatedRow data={workExperience} />
    </div>
  );
};

export default TabOne;
