import { useState } from 'react';
import { GrMoreVertical } from 'react-icons/gr';
import { GrFormClose } from 'react-icons/gr';

import styles from './styles.module.css';
import Menu from 'components/Menu';

const Navigation = () => {
  const [toggleNav, setToggleNav] = useState(false);

  return (
    <>
      <nav
        className={styles.nav}
        style={{ pointerEvents: toggleNav ? 'none' : 'auto' }}
      >
        {!toggleNav ? (
          <div
            className={styles.navButton}
            onClick={() => setToggleNav(true)}
            style={{ background: 'var(--primary)' }}
          >
            <GrMoreVertical
              className={styles.icon}
              style={{ color: 'var(--dark)' }}
            />
          </div>
        ) : (
          <div
            className={styles.navButton}
            style={{ background: 'var(--primary2)' }}
            onClick={() => setToggleNav(false)}
          >
            <GrFormClose className={styles.icon} style={{ color: 'white' }} />
          </div>
        )}
      </nav>
      <Menu toggleNav={toggleNav} setToggleNav={setToggleNav} />
    </>
  );
};

export default Navigation;
