export const routes = {
  home: '/',
  about: '/#about',
  experience: '/#experience',
  projects: '/#projects',
  contact: '#contact',
};

export const routesConfig = [
  {
    title: 'Home',
    url: routes.home,
    showInMenu: true,
  },
  {
    title: 'About',
    url: routes.about,
    showInMenu: true,
  },
  {
    title: 'Experience',
    url: routes.experience,
    showInMenu: true,
  },
  {
    title: 'Projects',
    url: routes.projects,
    showInMenu: true,
  },
  {
    title: 'Contact',
    url: routes.contact,
    showInMenu: true,
  },
];
