import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';

import { routesConfig } from 'routes/routesConfig';
import navBg from '../../assets/images/navBG.jpg';

import styles from './styles.module.css';

const Menu = ({ toggleNav, setToggleNav }) => {
  const [rotate, setRotate] = useState('');
  const [prevRotate, setPrevRotate] = useState('');
  const [listItems, setListItems] = useState([]);

  const navTl = useRef();
  const menuRef = useRef();
  const menuBgRef = useRef();
  const linksContainer = useRef();

  const handleLinkClick = () => {
    toggleNav && setToggleNav(false);
  };

  const handleHover = (index) => {
    let randomDegree;
    do {
      randomDegree = Math.random() < 0.5 ? '-15deg' : '15deg';
    } while (randomDegree === prevRotate[index]);

    const randomRotation = `rotateX(${randomDegree})`;
    setRotate({ ...rotate, [index]: randomRotation });
    setPrevRotate({ ...prevRotate, [index]: randomDegree });
  };

  const handleLeave = (index) => {
    setRotate({ ...rotate, [index]: '' });
  };

  useEffect(() => {
    const items = linksContainer.current.querySelectorAll('.links');
    setListItems(Array.from(items));
  }, []);

  useLayoutEffect(() => {
    let context = gsap.context(() => {
      gsap.set(menuRef.current, {
        display: 'none',
        duration: 0,
      });
      gsap.set(menuBgRef.current, {
        yPercent: -101,
      });
      gsap.set(listItems, {
        autoAlpha: 0,
        y: 300,
      });

      navTl.current = gsap.timeline({
        paused: true,
      });

      navTl.current
        .to(menuRef.current, {
          display: 'initial',
          duration: 0,
        })
        .to(menuBgRef.current, {
          yPercent: 0,
          duration: 0.5,
        })
        .to(listItems, {
          autoAlpha: 1,
          y: 0,
          stagger: 0.05,
          duration: 0.3,
        });
    });
    return () => context.revert();
  }, [listItems]);

  useLayoutEffect(() => {
    toggleNav ? navTl.current.play() : navTl.current.reverse();
  }, [toggleNav]);

  return (
    <div className={styles.menu} ref={menuRef}>
      <div ref={menuBgRef} className={styles.menuBackground}>
        <img src={navBg} className={styles.imgBackground} />
      </div>
      <ul className={styles.menuItems}>
        <div ref={linksContainer} className={styles.linkContainer}>
          {routesConfig.map(
            (item, index) =>
              item.showInMenu && (
                <li
                  className={`${styles.listItem} links`}
                  key={index}
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={() => handleLeave(index)}
                  style={{ transform: rotate[index] }}
                >
                  <Link
                    to={item.url}
                    className={styles.menuLinks}
                    onClick={() => handleLinkClick(item.url)}
                  >
                    {item.title}
                  </Link>
                </li>
              )
          )}
        </div>
      </ul>
    </div>
  );
};

export default Menu;
