import { projectsData } from 'data';
import ProjectsSectionDesktop from 'components/ProjectsSectionDesktop';
import useIsMobile from 'hooks/useIsMobile';
import ProjectsSectionMobile from 'components/ProjectsSectionMobile';

const Projects = () => {
  const isMobile = useIsMobile(1200);

  return (
    <div id='projects'>
      {isMobile ? (
        <ProjectsSectionMobile projectsData={projectsData} />
      ) : (
        <ProjectsSectionDesktop projectsData={projectsData} />
      )}
    </div>
  );
};

export default Projects;
