import { useState, useEffect, useRef, useMemo } from 'react';
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import { BsDot } from 'react-icons/bs';
import { ScrollTrigger } from 'gsap/all';

import useIsMobile from 'hooks/useIsMobile';
import useWindowSize from 'hooks/useWindowSize';

import styles from './styles.module.css';

const ProjectsSectionDesktop = ({ projectsData }) => {
  const projectsContainerRef = useRef();
  const projectContainerRef = useRef();
  const fadeInTl = useRef();
  const scrollTl = useRef();
  const trackRef = useRef();
  const titleRef = useRef();
  const pinContainerRef = useRef();

  const isMobile = useIsMobile(1200);
  const windowSize = useWindowSize();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const context = gsap.context(() => {
      const projects = gsap.utils.toArray('#projectsContainer');

      gsap.set(projects, {
        xPercent: 50,
        opacity: 0,
      });

      fadeInTl.current = gsap.timeline({
        scrollTrigger: {
          trigger: pinContainerRef.current,
          start: 'top bottom',
          preventOverlaps: true,
        },
      });

      fadeInTl.current
        .to(titleRef.current, {
          opacity: 0.1,
          duration: 2,
          ease: 'power1.out',
        })
        .to(
          projects,
          {
            xPercent: 0,
            opacity: 1,
            duration: 1,
            stagger: 0.25,
            ease: 'power2.out',
          },
          '<'
        );
    });

    return () => context.revert();
  }, [projectsData, isMobile]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const windowWidth = window.innerWidth;
    const trackWidth = trackRef.current.getBoundingClientRect().width;

    const animationScrollDistance = trackWidth - windowWidth;
    const animationSpeed = animationScrollDistance / 1.5;

    const scrollContext = gsap.context(() => {
      scrollTl.current = gsap.timeline({
        scrollTrigger: {
          trigger: projectsContainerRef.current,
          start: 'top top',
          end: `+=${animationSpeed}`,
          pin: true,
          scrub: 0.5,
          preventOverlaps: true,
        },
      });

      scrollTl.current
        .to(trackRef.current, {
          x: -animationScrollDistance,
          ease: 'none',
        })
        .to(
          titleRef.current,
          {
            xPercent: -30,
            ease: 'none',
          },
          '<'
        );
    });

    return () => scrollContext.revert();
  }, [projectsData, isMobile, windowSize]);

  return (
    <div ref={pinContainerRef}>
      <div ref={projectsContainerRef} className={styles.projectsContainer}>
        <h1 ref={titleRef} className={styles.title}>
          <span>Projects</span>{' '}
          <span>
            <BsDot />
          </span>{' '}
          <span>Projects</span>
        </h1>
        <div ref={trackRef} className={styles.projectsTrack}>
          {projectsData?.map((project, index) => (
            <div
              ref={projectContainerRef}
              id='projectsContainer'
              key={index}
              className={styles.projectContainer}
            >
              <h1 className={styles.projectTitle}>{project.title}</h1>
              <Link to={`/projects/${project.id}`} className={styles.link}>
                <div
                  id='projectImageContainer'
                  className={styles.projectImageContainer}
                >
                  <img src={project.image} alt={project.title} />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectsSectionDesktop;
