import img from 'assets/images/photo-project-1.jpg';
import img2 from 'assets/images/photo-project-2.jpg';
import { BsGithub } from 'react-icons/bs';

import SummitTech from 'assets/images/SummitTech/SummitTechCover.png';
import summitTech1 from 'assets/images/SummitTech/summittech1.png';
import summitTech2 from 'assets/images/SummitTech/summittech2.png';
import summitTech3 from 'assets/images/SummitTech/summittech3.png';
import summitTech4 from 'assets/images/SummitTech/summittech4.png';
import summitTech5 from 'assets/images/SummitTech/summittech5.png';

import buttonCover from 'assets/images/buttonComponent/buttonCover.png';
import button1 from 'assets/images/buttonComponent/button1.png';

import multiInputFormCover from 'assets/images/multiInputFormComponent/multiInputFormCover.png';
import multiInputForm1 from 'assets/images/multiInputFormComponent/multiInputForm1.png';
import multiInputForm2 from 'assets/images/multiInputFormComponent/multiInputForm2.png';

import selectCover from 'assets/images/selectComponent/selectCover.png';
import select1 from 'assets/images/selectComponent/select1.png';
import select2 from 'assets/images/selectComponent/select2.png';
import select3 from 'assets/images/selectComponent/select3.png';
import select4 from 'assets/images/selectComponent/select4.png';

import sidebarCover from 'assets/images/sidebarComponent/sidebarCover.png';
import sidebar1 from 'assets/images/sidebarComponent/sidebar1.png';
import sidebar2 from 'assets/images/sidebarComponent/sidebar2.png';

import gunpointCover from 'assets/images/gunpoint/gunpointCover.png';
import gunpoint1 from 'assets/images/gunpoint/gunpoint1.png';
import gunpoint2 from 'assets/images/gunpoint/gunpoint2.png';
import gunpoint3 from 'assets/images/gunpoint/gunpoint3.png';
import gunpoint4 from 'assets/images/gunpoint/gunpoint4.png';
import gunpoint5 from 'assets/images/gunpoint/gunpoint5.png';
import gunpoint6 from 'assets/images/gunpoint/gunpoint6.png';

export const projectsData = [
  {
    id: '1',
    image: SummitTech,
    title: 'Summit Tech Website',
    description: [
      {
        paragraph:
          'Working on this project, I decided to get out of my comfort zone, trying to recreate functionalities that I have seen on other web pages. One such functionality was the hover effect, which is commonly achieved through libraries like Tilt.js.',
      },
      {
        paragraph:
          'To gain a deeper understanding of how this effect is achieved, I decided to attempt to replicate it from scratch rather than using a pre-existing library. This was a challenging process that required me to experiment with different techniques. Ultimately, I gained a better understanding of how this effect can be achieved and expanded my knowledge',
      },
      {
        paragraph:
          'To add an extra touch of creativity to the project, I incorporated animations using the GSAP library. Through this process, I gained valuable experience and insights into how to effectively use this tool.',
      },
      {
        paragraph:
          'Overall, this project was an excellent opportunity for me to step out of my comfort zone and develop my skills as a web developer.',
      },
    ],
    technologies: ['HTML', 'CSS', 'JavaScript', 'GSAP'],
    buttons: [
      {
        buttonText: 'See Project',
        buttonUrl: 'https://summittech.ro/',
      },
    ],
    images: [summitTech1, summitTech2, summitTech3, summitTech4, summitTech5],
  },
  {
    id: '2',
    image: sidebarCover,
    title: 'Sidebar Component build with Redux and Typescript',
    description: [
      {
        paragraph:
          'Building this component presented two challenges. The first challenge was to ensure easy accessibility from anywhere in the application. The component needed to be accessible to other parts of the codebase without modifications.',
      },
      {
        paragraph:
          'The second challenge was to make the component reusable for different functionalities. It was essential to build the component in a way that it could adapt to various use cases and be easily integrated with different features or modules within the application.',
      },
      {
        paragraph:
          'To achieve this functionality, I utilized Redux for state management.  The state includes information such as whether the sidebar is open or closed, the component to be rendered within the sidebar, and the component props. I have also added an onClose function mainly for updating an endpoint if necessary.',
      },
      {
        paragraph:
          "The content section uses a DynamicComponent component, which dynamically renders the appropriate content based on the type specified in the configuration. The DynamicComponent component lazy loads the content components using React's lazy and Suspense mechanisms. It maps the content types to their respective components and renders them within a Suspense component to handle loading states.",
      },
      {
        paragraph:
          'Building this component was challenges, but with some assistance I successfully completed it.',
      },
    ],
    technologies: ['HTML', 'CSS', 'JavaScript', 'React', 'Redux', 'Typescript'],
    buttons: [
      {
        buttonText: 'live demo',
        buttonUrl: 'https://sidebarcomponent.netlify.app/',
      },
      {
        buttonText: <BsGithub />,
        buttonUrl:
          'https://github.com/CodyHiII/SidebarComponent-With-Redux/tree/main/src',
      },
    ],
    images: [sidebar1, sidebar2],
  },
  {
    id: '3',
    image: multiInputFormCover,
    title: 'Multi-Input Form Component',
    description: [
      {
        paragraph:
          ' I took on the challenge of building the MultiInputForm component from scratch. The component was inspired by credit card input forms.',
      },
      {
        paragraph:
          "Building the MultiInputForm was an exciting endeavor that allowed me to deepen my understanding of React's and expand my skills in web development. The component utilizes essential React hooks such as useState, useRef, and useEffect to manage state, handle user interaction.",
      },
      {
        paragraph:
          'One of the key features of the MultiInputForm is its flexibility. It dynamically adjusts the number of input fields based on the specified numberOfInputs prop, which allows for customization according to the requirements of different projects.',
      },
      {
        paragraph:
          'In conclusion, the MultiInputForm helped my ability to develop reusable and customizable components in React.',
      },
    ],
    technologies: ['HTML', 'CSS', 'JavaScript', 'React'],
    buttons: [
      {
        buttonText: 'live demo',
        buttonUrl: 'https://multiinputcomponent.netlify.app/',
      },
      {
        buttonText: <BsGithub />,
        buttonUrl:
          'https://github.com/CodyHiII/MultiInputForm/blob/main/src/components/MultiInputForm/index.jsx',
      },
    ],
    images: [multiInputForm1, multiInputForm2],
  },
  {
    id: '4',
    image: buttonCover,
    title: 'Dynamic Button Component with TypeScript',
    description: [
      {
        paragraph:
          "I've developed a highly versatile button that dynamically adjusts its behavior using TypeScript. It can handles conditional props, ensuring that required props are automatically enforced based on specific conditions.",
      },
      {
        paragraph:
          "Additionally, I've implemented a wrapper function that allows the button to seamlessly transform into a link, anchor tag, or standard button, providing maximum flexibility.",
      },
    ],
    technologies: ['HTML', 'CSS', 'React', 'Typescript'],
    buttons: [
      {
        buttonText: 'See project',
        buttonUrl: 'https://btncomponent.netlify.app/',
      },
      {
        buttonText: <BsGithub />,
        buttonUrl:
          'https://github.com/CodyHiII/ButtonComponent/blob/main/src/components/Button/index.tsx',
      },
    ],
    images: [button1],
  },
  {
    id: '5',
    image: selectCover,
    title: 'Multiple Selection Component with keyboard navigation',
    description: [
      {
        paragraph:
          'As a developer, I wanted to create a customizable select component in React that would allow users to navigate and make selections using their keyboard. I saw this as an opportunity to enhance accessibility and provide a seamless user experience for keyboard users.',
      },
      {
        paragraph:
          'The component supports both single and multiple selection modes, determined by the multiple prop. When an option is selected, the onChange function is called, updating the selected value(s) accordingly. I implemented the logic to handle selection and deselection of options based on the selection mode.',
      },
      {
        paragraph:
          'To enable keyboard navigation, I implemented event handlers for keydown events. When the component is in focus, the user can press Enter or Space to toggle the dropdown menu. Arrow Up and Arrow Down keys allow the user to navigate through the options, highlighting each option as they move. Pressing Escape closes the dropdown menu.',
      },
    ],
    technologies: ['HTML', 'CSS', 'JavaScript', 'React', 'Typescript'],
    buttons: [
      {
        buttonText: 'live demo',
        buttonUrl: 'https://multiselectcomponent.netlify.app/',
      },
      {
        buttonText: <BsGithub />,
        buttonUrl:
          'https://github.com/CodyHiII/SelectComponent/blob/main/src/components/Select/index.tsx',
      },
    ],
    images: [select1, select2, select3, select4],
  },
  {
    id: '6',
    image: gunpointCover,
    title: 'GUNPOINT Website',
    description: [
      {
        paragraph:
          "Gunpoint began as a straightforward project. The website served a purpose, establishing credibility for a business during the early stages of it's development. The website was built with React and mainly bootstrap.",
      },
    ],
    technologies: ['HTML', 'CSS', 'JavaScript', 'React'],
    buttons: [
      {
        buttonText: 'live demo',
        buttonUrl: 'https://classifiedops.ro/',
      },
    ],
    images: [gunpoint1, gunpoint2, gunpoint3, gunpoint4, gunpoint5, gunpoint6],
  },
];
