import React from 'react';

import AnimatedRow from 'components/AnimatedGrid';

import styles from './styles.module.css';

import { school, study } from 'data';

const TabTwo = () => {
  return (
    <div>
      <h1 className={styles.tabTwoTitle}>Certifications</h1>
      <AnimatedRow data={study} />
      <h1 className={styles.tabTwoTitle}>Education</h1>
      <AnimatedRow data={school} />
    </div>
  );
};

export default TabTwo;
