import React from 'react';

import Tabs from 'components/Tabs';

import { experienceTabs } from './experienceTabsConfig/experienceTabs';

import styles from './styles.module.css';

const Experience = () => {
  return (
    <div className={styles.experienceContainer} id='experience'>
      <Tabs config={experienceTabs} />
    </div>
  );
};

export default Experience;
