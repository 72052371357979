export const workExperience = [
  {
    date: 'Dec 2023 - present',
    role: 'Lead Frontend Developer and UX/UI Designer',
    company: 'Semper Cozy',
  },
  {
    date: 'May 2022 - Dec 2023',
    role: 'React Developer',
    company: 'Beyond Enterprizes',
  },
  {
    date: 'Dev 2021 - May 2022',
    role: 'Tester',
    company: 'Quantic Lab',
  },
  {
    date: 'Sep 2019 - Feb 2021',
    role: 'Graphic Designer',
    company: 'General Prest',
  },
];

export const school = [
  {
    date: 'Sep 2014- May 2017',
    school: 'Liceul Tehnologic Constantin Brâncuși',
  },
];

export const study = [
  {
    issued: 'May 2019',
    name: 'JavaScript Algorithms and Data Structures',
    issuer: 'freeCodeCamp',
  },
  {
    issued: 'May 2019',
    name: 'Frontend Development Libraries',
    issuer: 'freeCodeCamp',
  },
  {
    issued: 'Apr 2019',
    name: 'Responsive Web Design',
    issuer: 'freeCodeCamp',
  },
];

// export const workExperience = [
//   {
//     date: 'Mar 2023 - Present',
//     role: 'Full Stack Developer',
//     company: 'Lorem Ipsum Co.',
//   },
//   {
//     date: 'Jun 2022 - Feb 2023',
//     role: 'Software Engineer',
//     company: 'Dolor Sit Amet Corp.',
//   },
//   {
//     date: 'Jan 2021 - Mar 2022',
//     role: 'UI Designer',
//     company: 'Lorem Corp.',
//   },
// ];

// export const school = [
//   {
//     date: 'Sep 2018- Jun 2022',
//     school: 'Lorem Ipsum University',
//   },
// ];

// export const study = [
//   {
//     issued: 'Jun 2022',
//     name: 'Advanced Data Structures and Algorithms',
//     issuer: 'Lorem Ipsum University',
//   },
//   {
//     issued: 'May 2022',
//     name: 'Full Stack Web Development',
//     issuer: 'Udemy',
//   },
//   {
//     issued: 'Apr 2021',
//     name: 'UI/UX Design Fundamentals',
//     issuer: 'Coursera',
//   },
// ];
